
   .featured-profiles {
    padding:0px 20px;
    text-align: center;
    padding-top:70px ;
  }
  
  .featured-profiles h2 {
    font-size: 32px;
    margin-top: 10px;
    background: -webkit-linear-gradient(360deg, #391c04 18%, #fdcd5ee3 51%, rgb(69 47 4 / 87%) 41%);
    background-clip: text;
    -webkit-background-clip: text;
    
    -webkit-text-fill-color: transparent;

  }
  
  .profiles-wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 30px;
  }
  
  .profiles {
    display: flex;
    width: max-content;
    animation: scrollProfiles 30s linear infinite;
  }
  
  @keyframes scrollProfiles {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  .profiles-wrapper:hover .profiles {
    animation-play-state: paused;
  }
  
  .profile-card {
    flex: 0 0 300px;
    margin-right: 20px;
    width: 300px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 44px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .profile-card img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 50% 10%;
    border-radius: 44px;
    margin-bottom: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    
  }
  .profile-card img:hover {
    transform: translateY(-10px);
    transform: scale(1.1);
  }
  
  .profile-card h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: rgb(87 130 229);
  }
  
  .profile-card p {
    font-size: 16px;
    color: #666;
  }
  .profile-card  .id{
    font-weight: bold;
    color: #bd196f;
  }
  
  .profile-card:hover {
    transform: translateY(-10px);
    transform: scale(1.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .profile-card {
      flex: 0 0 250px;
    }
  }
  