/* PaymentPage.css */
.payment-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    padding-top: 107px;
  }
  
  .payment-methods {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .payment-methods button {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 10px 20px;
    cursor: pointer;
    flex-grow: 1;
    margin: 0 5px;
    transition: background-color 0.3s ease;
  }
  
  .payment-methods button:hover {
    background-color: #e0e0e0;
  }
  
  .payment-methods button.active {
    background-color: #d0d0d0;
    border-color: #aaa;
  }
  
  .payment-details {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 5px;
    width:100%;
  }
  
  .payment-details h2 {
    margin-bottom: 20px;
  }
  
  .payment-details form {
    display: flex;
    flex-direction: column;
  }
  
  .payment-details form div {
    margin-bottom: 15px;
  }
  
  .payment-details label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .payment-details input,
  .payment-details select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .payment-details button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .payment-details button:hover {
    background-color: #45a049;
  }
  
  @media (max-width:768px) {
.payment-page {
    display: flex;
  }
.payment-methods{
    flex-direction: column;
}}