/* Apply box-sizing to all elements for uniform sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.profile-create-bg {
  /* background: url("../images//background-img.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  position: relative;
  top:70px;
  margin-bottom: 80px;
  padding: 40px;
}
.profile-create-bg .later{
  color: #666;
  font-weight:bold;
}
.profile-container {
  background-color: #e8e8e8;
  max-width: 58%;
    margin: 0 auto;
    padding: 30px;

    border-radius: 8px;
    box-shadow: 0 8px 27px rgba(0, 0, 0, 0.1);
    margin: 34px auto;
   

}

.profile-heading {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.profile-form-label {
  font-size: 16px;
  font-weight: 500;
  color: #555;
}

.profile-form-input,
.profile-form-select,
.profile-form-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 5px;
}

.profile-form-input:focus,
.profile-form-select:focus,
.profile-form-textarea:focus {
  border-color: #007bff;
  outline: none;
}

.profile-submit-button {
  background-color: #2c9fec;
  /* background: linear-gradient(to right, #6cb2f4, #30ddcf);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75); */
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-submit-button:hover {
  background-color: #218838;
}

.profile-submit-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.profile-form-textarea {
  resize: vertical;
}
.profile-form i{
background: green;
    color: white;
    padding: 1px;
    margin-left: 10px;
  }
    .uploadpic{
      display: flex;
  background-image: url("../images/img11.jpg");
  background-image: url("https://png.pngtree.com/thumb_back/fw800/background/20240103/pngtree-floral-blooms-wedding-invitation-with-a-touch-of-texture-image_13919583.png");
  background-size: cover;
  background-image: url("../images/profilebg1.png");
    
  background-position: center;
      background-repeat: no-repeat;

    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    height:400px;
    margin: 10px;
    }

    .uploadpic .profile-upload{
    display: flex;
    gap:10px;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding-right: 10px;
    border-radius: 5px;
    padding: 10px;
    }
    .uploadpic img{
      height: 219px;
    width: 219px;
    border-radius: 100%;
    object-fit: cover;
    object-position: 50% 10%;
    }
    .uploadpic button input{
     width:99px;
    }
    .uploadpic  button{
      background: #52becd;
    border: none;
    padding: 26px;
    color: white;
    border-radius: 48px;
    }
    @media screen  and (max-width:970px) {
      .uploadpic{
        flex-direction: column;
      }
      .uploadpic div{
        border-right:none;
        padding-right: 0px;
        margin-right: 0px;
      }
    }

    .custom-file-upload{
      display: inline-flex;
      gap: 9px;
      align-items: center;
      cursor: pointer;
      background-color: #f0f0f0;
      padding: 10px 20px;
      border-radius: 5px;
      border: 1px solid #ccc;
      transition: background-color 0.3s;
      background: linear-gradient(to right, #4b8eec, #6cb2f4, #30ddcf);
      box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
      color: white;
    }
    .custom-file-upload:hover {
      background-color: #e0e0e0; /* Change background on hover */
    }
    .custom-file-upload input[type="file"] {
      display: none; /* Hide the actual file input */
    }
    .jioid-profile{
      margin: 0px;
      background: -webkit-linear-gradient(0deg, rgb(87, 130, 229) 0%, rgba(49, 196, 190, 0.75) 100%) text;
    -webkit-text-fill-color: transparent;
    color: #03534fbf;
    font-weight: 900 !important;

    }
    .add-edit{
      font-weight: bold;
      color: #55abf7;
    }
    .add-edit:hover{
      font-size: 17px;
     
    }
    @media (max-width:800px) {
      .profile-container {margin: 0px;max-width: 100%;padding: 10px;}
      .profile-create-bg {
      padding: 0px;}
    }


    .plan-limit{
      font-size: 15px;
    }