

.user-details{
  display: flex;
 gap: 20px;
  flex-wrap: wrap; 
  justify-content: center;
}
.each-profile{
  width: 32%;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  gap: 16px;
  padding: 9px;
  transition: transform .3s ease, box-shadow .3s ease;
  width: 42%;
  display: grid;
  grid-template-columns: 50% 50%;
  
}
.each-profile h4{
  background: -webkit-linear-gradient(0deg, rgb(87 130 229) 0%, rgb(49 196 190 / 75%) 20%);
    /* background-clip: text; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.each-profile h4 span{
  color: red;
   
    background: -webkit-linear-gradient(0deg, #FF6F61 0%, #FF6F61 100%);

   
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
@media screen and (max-width:1024px) {
.each-profile{
  width: 45%;}
}

.each-profile:hover:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
.user-details div img{
  border-radius: 104px;
  width: 223px;
  object-fit: cover;
  height: 391px;
  background-position: center;
}
@media (max-width:768px) {
  .user-details div{
      width: 100%;
  }
} 

.pagination {
margin-top: 20px; 
display: flex;
justify-content: flex-end;
margin-right: 10px; 
}
.pagination .pagination-inner{
  
border: 1px solid #20B2AA;
padding: 11px 26px;
border-radius: 31px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  color: #20B2AA;
  background: white;
}

.pagination button.active {

font-weight: bold;
    text-decoration: underline;
    color: white;
    background: #20B2AA;
}


  
.profile-info {
cursor: pointer;
}

