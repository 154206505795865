.card-img{   

    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-img button{
  width:120px;
  padding: 0px 0px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 190px;
    height: 40px;
    font-size: 17px;
    background: linear-gradient(to right, #4b8eec, #6cb2f4, #30ddcf);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}
@keyframes scrollProfiles {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
.card-profiles{
    display: flex;
    width: max-content;
    animation: scrollProfiles 9s linear infinite alternate-reverse;
}
.card-pro {
    flex: 0 0 142px;
    /* margin-right: 20px; */
    /* width: 300px; */
    /* padding: 20px; */
    background-color: #f9f9f9;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.img-back {
    
    object-fit: cover;
    object-position: 50% 10%;
    border-radius: 150px;
    margin-bottom: 10px;
  }
  .img-Featured {
    
    object-fit: cover;
    object-position: 50% 33%;
    border-radius: 8px;
    margin-bottom: 10px;
  }
 .image-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .grid-container{
    width: 0px !important;;
  }