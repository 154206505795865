.matched-users{
    display:grid;
    grid-template-columns:40% 40%;
    justify-content:center;
    align-items:center;
    gap:20px;
    flex-wrap:wrap;
}
.each-user{
  gap:10px;
    display:grid;
    grid-template-columns:30% 70%;
    font-size: 16px;
}
.each-user img{
    width:100%;
}