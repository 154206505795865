.why-are-we-better {
  padding: 60px 20px;
  text-align: center;
  padding-top: 0px;
  background: linear-gradient(177deg, #ffffff, #e8f5fd, #b4e1ff);
  border-radius: 10px;

  margin: 40px auto;
  max-width: 100%;
  margin-bottom: 0px;
}

.landing-active {
  background: linear-gradient(182deg, #ffffff, #b4e1ff, #ffffff);
}

.why-we-are-better-sec img {
  width: 100%;
}

.why-are-we-better h2 {
  font-size: 36px;
  margin-bottom: 10px;
  background: -webkit-linear-gradient(360deg, #17bcd3 44%, rgb(87 130 229) 51%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.why-are-we-better p {
  font-size: 18px;
  color: #666;
  margin-bottom: 40px;
}

.benefits {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 43px;
}

.benefit-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  width: 30%;
  margin-bottom: 20px;
  box-shadow: 0 35px 13px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
}

.benefit-card img {
  width: 150px;

}


.benefit-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px #30ddcf1f;
  border: 1px solid #30ddcf1f;
}

.benefit-card .icon {
  font-size: 100px;
  margin-bottom: 20px;
  color: #ff6f61;
  /* Add a vibrant color to the icons */
}

.benefit-card h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #EF5A6F;
  font-weight: 600;
}

.benefit-card p {
  font-size: 16px;
  color: #666;
}

@media (max-width: 1024px) {
  .benefit-card {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .benefit-card {
    width: 100%;
  }
}

.why-we-are-better-sec .modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding: 30px;
  width: 500px;
  height: 50vh;
  justify-content: center;
  gap: 10px;
}

.modal-content h3 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 30px;

}

.why-we-are-better-sec .modal-content form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60%;
  flex-wrap: wrap;
}

.why-we-are-better-sec .modal-content form label {
  gap: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 20px;
}

@media (max-width:600px) {
  .why-we-are-better-sec .modal-content form label {
    justify-content: center
  }

}

.why-we-are-better-sec .modal-content form label input {
  outline: none;
  border: 1px solid grey;
  box-shadow: 20px 20px 60px #bebebe,
    -20px -20px 60px #ffffff;

  /* width: 200px; */
  font-weight: bold;
  padding: 5px 10px;

}

.mailsendbtns {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.modal-content p {
  color: red;
  font-weight: 600;
}