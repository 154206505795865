.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: white;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0.7;
    z-index: 10;
  }
  
  .scroll-to-top:hover {
    opacity: 1;
    transform: translateY(-15px);
  }
  