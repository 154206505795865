.border-footer {
  width: 100%;

}

.footer {
  background: linear-gradient(135deg, #6e48aa, #9d50bb);
  background: rgb(0, 123, 255, 0.3);


  text-align: center;
  position: relative;
  width: 100%;
  display: flex;
  margin-top: -4px;
  background: rgb(209 233 222 / 30%);
}

.footer .img1 {
  align-self: flex-end;
  width: 250px;
}

.footer .img2 {
  align-self: flex-start;
  width: 250px;
}

.footer .content {
  align-self: center;
  flex-grow: 1;
}


@media screen and (max-width:900px) {
  .footer .img2 {
    display: none;
  }

  .footer .img1 {
    display: none;
  }

  .footer {
    padding: 20px 30px;
  }
}

.footer-top {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  /* Place content above the overlay */
  /* Text color */
  color: rgb(3 83 79 / 75%);
}

.footer-logo {
  font-size: 28px;
  font-weight: bold;
  font-style: italic;
  background: -webkit-linear-gradient(164deg, #d3bd54 65%, rgb(49 41 7 / 87%) 20%);
  background: -webkit-linear-gradient(164deg, #c1ab43 50%, rgba(49, 41, 7, 0.87) 57%);
  background: #c1ab43;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.footer-logo::after {
  content: '';
  position: absolute;
  bottom: -5px; 
  left: 0;
  right: 0;
  height: 3px;
  background: -webkit-linear-gradient(164deg, rgba(49, 41, 7, 0.87) 60%, #c1ab43);
}


.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: solid 1px #5e8d7c;

}

.footer-section {
  flex: 1;
  margin: 20px 0;
  position: relative;
  z-index: 1;
  /* Place content above the overlay */
  /* Text color */
  display: flex;
  flex-direction: column;


  align-items: center;
}

.footer-section h2 {
  font-size: 18px;
  text-align: center;
  color: #a95f5f;
  color: #ff6f61;
  margin-bottom: 15px;
  width: 261px;

}

@media screen and (max-width:710px) {
  .footer-section h2 {
    text-align: left;
  }
}

.footer-section p,
.footer-section ul {
  font-size: 16px;
  color: rgb(3 83 79 / 75%);
  width: fit-content;
  padding-left: 0;
  font-weight: 500;
}

.footer-section li {
  margin: 5px 20px;
  list-style-type: none;
  margin-left: 0;
}

.footer-section a {
  color: rgb(3 83 79 / 75%);
  text-decoration: none;
}

.footer-section a:hover {
  color: #1978d0;
  font-weight: 700;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.social-icons a {
  font-size: 40px;
  transition: all 0.3s ease-in;

}

.social-icons a:hover {
  transform: scale(1.3);
  transition: all 0.3s ease-in;
  border-radius: 10%;
}

.about-us-2-social-x {
  color: black;
}

.about-us-2-social-insta {
  color: white;
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1)
}

.about-us-2-social-fb {
  color: #0653f8;
}

.about-us-2-social-linkedin {
  color: #061ef4;
}

.footer-bottom {
  border-top: 0px solid black;
  padding-top: 5px;
  font-size: 14px;
  color: rgb(3 83 79 / 75%);
  position: relative;
  z-index: 2;
  /* Place content above the overlay */
  /* Text color */

}

.about-us-2-driver span {
  color: #593cfb;
}

.Footer-bottom-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 79px;
}

.resp-arr .icon-toshow {
  display: none;
}

@media screen and (max-width:710px) {

  .footer-section {
    width: 100%;
    border-bottom: solid 1px rgb(209 233 222);
    margin: 10px;
    align-items: unset;
  }

  .footer-section p,
  .footer-section ul {
    display: none;
  }

  .resp-arr {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .resp-arr .icon-toshow {
    display: block;

  }

  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .active p,
  .active ul {
    display: block;
    text-align: start;
  }
}

.footer-links {
  text-align: center;
  margin: 10px 0;
}

.footer-links a {
  margin: 0 15px;
  /* color: #A95F5F; */
  color: #a95f5f;
  text-decoration: none;
  font-weight: 500;
  /* padding: 5px 10px; */
  text-decoration: underline;
  /* color: #029b93bf; */
  color: rgb(87 130 229);
  font-weight: bold;
}

.footer-links a:hover {
  text-decoration: underline;
  /* color: #029b93bf; */
  color: rgb(87 130 229);
  font-weight: bold;
  padding: 0;
  transform: scaleX(1.1);
}

.about-jio span {
  font-weight: bold;
}

.about-jio {
  text-align: justify;
}

.mailphone{

  cursor: pointer;
  width: fit-content;
  text-align: center;
  margin: 0 auto !important;
} 
@media screen and (max-width:925px) {
  .footer-logo {
    font-size: 20px;}
}
@media screen and (max-width:700px) {
  .footer {
    padding: 50px 30px;
  }
  .footer-links a {font-size: 12px;}
}