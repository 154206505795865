.Landing-page{
   
    background-image: url("../images/backgroundCouple2.jpg");
    background-position: center;
    background-size: cover;
    height: 100vh;

    /* display: flex;
    flex-flow: wrap;
    position: absolute;
    margin-top: 24%; */
}