.bottom-section {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 80vh; */
  background-color: white;
  border-radius: 8px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  position: relative;
  top: 107px;
  padding: 30px;
  width: 100%;
  margin-bottom: 118px;
}

.bottom-section form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: fit-content;
  gap: 20px;
  flex-direction: column;

}

.radios {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}
.radios label{
  font-weight: bold;
  font-size: 18px;
  background: deepskyblue;
  padding: 10px 5px;
  border-radius: 5px;
  color: white
}

.searchSubmit {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}

.searchSubmit .line-shade {
  font-weight: 900;
}


.profiles-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width:1400px) {
  .profiles-container{
    grid-template-columns: repeat(1, 1fr);
  }
}


.reveal-btn {
  background: green;
  padding: 10px;
  border-radius: 24px;
}

.profile-user {
  display: flex;
  gap: 30px;
  padding: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.profile-user img {
  object-fit: contain;
  width: 300px;
  height: 400px;
  border-radius: 20px;
  filter: contrast(150%) brightness(100%) saturate(125%);

}

.profile-user .profile-info {
  width: 250px;

}

.bottom-section form input[type="text"] {
  width: 100%;
  padding: 12px;
  /* margin-bottom: 20px; */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.bottom-section form input[type="text"]:focus {
  border-color: #007bff;
  outline: none;
}

.bottom-section form button[type="submit"] {
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bottom-section form button[type="submit"]:hover {
  background-color: #0056b3;
}

.inprofiled{
  position: none !important;

}