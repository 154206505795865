.add-upload-section{
    position: relative;
    top:70px;
    display: flex;
    justify-content: center;
    margin:30px auto;
    margin-bottom: 100px;
    padding: 20px;
}
.upload-img{
    background: -webkit-linear-gradient(0deg, rgb(223 114 114) 0%, rgb(49 196 190 / 75%) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.images-list{
    display: flex;
    flex-wrap: wrap;
    padding: 60px 30px;
    gap:20px;
    background: linear-gradient(rgb(201, 223, 223),rgb(181, 245, 245),rgb(158, 201, 232)
    );
    justify-content: center;
    align-items: center;
}
.list-images{
    width:250px;
    height:300px;
}
.imagesDiv{
    display: flex;
    justify-content:center;
    align-items: start;
}
.add-img{
    width:200px;
    height:300px;
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.plus-icon{
    font-size: 57px; 
}
.images-list {
    display: flex;
    flex-wrap: wrap;
    padding: 60px 30px;
    gap: 20px;
    background: linear-gradient(rgb(201, 223, 223), rgb(181, 245, 245), rgb(158, 201, 232));
    justify-content: center;
    align-items: center;
  }
  
  .imagesDiv {
    position: relative; /* Allow positioning of the xmark */
    display: flex;
    flex-direction: column; /* Stack the image and xmark vertically */
    align-items: center; /* Center the items horizontally */
  }
  
  .list-images {
    width: 250px;
    height: 300px;
/* Maintain aspect ratio and cover the container */
  }
  
  .deletexmark {
    position: absolute; /* Position the xmark on top of the image */
    top: 10px; /* Adjust top position */
    right: 10px; /* Adjust right position */
    cursor: pointer; /* Show pointer cursor */
    background-color: #ff6f61; /* Optional: add background color */
    border-radius: 50%; /* Make it circular */
    padding: 5px;
     /* Add some padding */
  }


  
  .confirmation-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure it's on top */
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .popup-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  

  /* From Uiverse.io by lenfear23 */ 
  .popup-buttons button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    width: 150px;
    height: 50px;
    background-image: linear-gradient(to top, #D8D9DB 0%, #fff 80%, #FDFDFD 100%);
    border-radius: 30px;
    border: 1px solid #8F9092;
    transition: all 0.2s ease;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #606060;
    text-shadow: 0 1px #fff;
  }
  
  .popup-buttons button:hover {
    box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 3px 3px #CECFD1;
  }
  
  .popup-buttons button:active {
    box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 5px 3px #999, inset 0 0 30px #aaa;
  }
  
  .popup-buttons button:focus {
    box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 5px 3px #999, inset 0 0 30px #aaa;
  }
  
  
  