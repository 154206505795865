.favorites-list-container {
  padding: 20px;
  text-align: center;
  margin-top: 70px;
}

.favorites-heading {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.favorites-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;

}

@media (max-width: 925px) {
  .favorites-list-container {
    margin-top: 140px;

  }

  .favorites-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 770px) {
  .favorites-list-container {
    margin-top: 70px;
  }
}

@media (max-width: 600px) {
  .favorites-list {
    grid-template-columns: 1fr;
  }
}

.favorite-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
}

.favorite-image {
  width: 40%;
  float: left;
}

.favorite-image img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

.favorite-details {
  padding: 10px;
  width: 55%;
  float: right;
}

.default-avatar {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.favorite-card::after {
  content: "";
  display: table;
  clear: both;
}

.favorite-icon{
  position: absolute;
  top: 20px;
  right: 20px;
}