.help-page {
  position: relative;
  position: relative;
  top: 70px;
  /* height: 100vh; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  margin-bottom: 92px;
}

.help-page .help {
  width: 73%;
}

.help-header {
  background-color: #98bddf;
  display: flex;
  justify-content: space-between;
  padding: 20px;

}

.each-aws {
  padding: 10px;
  margin-left: 20px;
}

.help-methods button {
  border: none;
  background-color: transparent;
  font-size: 18px;
  margin: 15px;
  color: #03534fbf;
  font-weight: bold;
  cursor: pointer;
}

.help-methods .active {
  /* text-decoration: underline;
    text-decoration-color: blue; */
    border-bottom: 2px solid rgb(26 114 125);
    /* width: fit-content; */
    color: #d53717c2;
}

.each-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.each-card {
  padding: 10px;
  background-color: rgb(245 249 249);
  color: #03534fbf;
}

.icon-toshow {
  color: #ed7932;
}

.each-question {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.each-question p:first-of-type {
  font-weight: bold !important;
}

.each-question p:first-of-type span {
  color: #ed7932;
}

.each-aws a {
  color: #ed7932;
  text-decoration: none;
}

.contact-ways .country-name {
  display: flex;
  margin-bottom: 10px;
}

.contact-ways>p {
  color: #ed7932;
  font-weight: bold;
  font-size: 23px;
}

.contact-ways div img {
  width: 22px;
  height: 22px;
  border-radius: 4px;

}

.contact-details {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
}

.contact-ways div p {
  margin: 0;
  padding-left: 10px;

  span{
    font-weight: 700;
    cursor: pointer;
  }
}

.feedback-container {
  display: grid;
  grid-template-columns: 43% 50%;
  justify-content: center;
  align-items: center;
}

.feedback-form-container {
  /* max-width: 639px; */
  margin: 50px auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;

}

.feedback-form-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  background: -webkit-linear-gradient(63deg, rgb(12 14 17) 0%, rgb(244 176 70 / 75%) 100%);
  /* background-clip: text; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feedback-form-container h2 span {
  /* color: red; */
  background: -webkit-linear-gradient(63deg, #a95f5f 0%, rgb(244 70 70 / 75%) 100%);
  /* background-clip: text; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feedback-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border 0.3s;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  border-color: #007bff;
  outline: none;
}

.submit-btn {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  background: linear-gradient(to right, #6cb2f4, #30ddcf);
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}

.submit-btn:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .feedback-form-container {
    margin: 20px;
    padding: 20px;
  }
}

@media (max-width: 900px) {
  .feedback-container img {
    display: none;
  }

  .feedback-container {
    display: flex;
  }
}