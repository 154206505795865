.dropdown {
    position: relative;
  
  }
  
  .dropdown-header {
    padding: 0px 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: #f9f9f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
  }
  
  .arrow {
    margin-left: 10px;
    color: hsl(0deg 5.71% 65.92%);
    font-weight: bold;
  }
  .arrow:hover {
    color: hsl(0, 20%, 4%);
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
    height: 100px;
  }
  .dropdown-menu label{
    color: black;
    white-space: nowrap;
  }
  .dropdown-menu input{
    width:18px;
  }
  
  .dropdown-item {
    padding: 8px 10px;
    display: grid;
    grid-template-columns: 22% 70%;
    gap: 3px;
    align-content: space-between;
    justify-items: start;
    align-items: center;

  }
  
  .selected-options {
    margin-top: 20px;
  }
  
  .selected-options ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .selected-options li {
    margin-bottom: 5px;
  }
  