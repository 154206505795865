.homepage {
  
    background-image: url("../images//background-img.jpg");
    background-image: url("../images/bg3\ \(2\).jpeg");
    background-size: cover;
    
background-position: center;
    background-repeat: no-repeat;
    color: white;
    text-align: center;
    padding: 70px 20px;
    height: 120vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    position: relative;
    
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    
}
.para-display{
    background: -webkit-linear-gradient(0deg, rgb(87 130 229) 0%, rgb(49 196 190 / 75%) 69%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.count-display{
    font-weight: 900;
    font-size: 29px;
    background: -webkit-linear-gradient(0deg, #FF6F61 0%, #FF6F61 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header {
    background-color: rgb(66, 117, 66, 0.5);
    background: rgb(247, 68, 4,0.8);
    /* background: rgba(24, 199, 103, 0.3); */
    background: rgb(0,0,0,0.8);
    padding: 20px;
    border-radius: 10px;
    width: 90%;
 
    
}

.title {
    font-size: 32px;
    margin-bottom: 1px;
    color: #00aaff;
    margin-top: 0px;
}

.search-box {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: 10px;
    column-gap: 33px;
  
}

.search-field label {
    display: block;
    text-align: center;
    margin-bottom: 5px;
    font-weight: 600;
    color: white;
    
}

.search-field select,
.search-field input {
    padding: 5px;
    margin: 5px 0;
    width: 100px;
    font-size: 15px;
    /* background-color:rgb(0,0,0,0.5); */
    color: black;
}

.search-field span{
    display: block;
}


.search-button {
    padding: 0px 0px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 190px;
    height: 40px;
    font-size: 17px;
    background: linear-gradient(to right, #4b8eec, #6cb2f4, #30ddcf);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}
.search-field{
    width: 150px;
    color: black;
}

.search-button:hover{
  
    background-color: #00aaff;
}
@media (max-width: 799px) {
    .search-box {
        column-gap: 8px;
    }
    

}

@media (max-width: 800px) {
   
    
    .Main-content{
        position: relative;
        top: 69px;
    }
   .header{
    height: auto;
    padding: 20px;
    background: rgb(0,0,0,0.5);
   }
   .title{
    display: none;
   }
    
    .search-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    .search-field {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0px 0;
    }
    
    .withimages{
        display: flex;
        flex-direction: row;
        width: fit-content;
    }
    
    .search-field select,
    .search-field input {
        padding: 8px;
        font-size: 16px;
        width: auto;
        flex-grow: 1; 
    }
    
    .search-button {
        background-color: #00aaff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 50%;
        height: 40px;
        font-size: 18px;
        margin-top: 0px;
    }
    

    .title {
        font-size: 24px; 
    }


    .height-Mantain{
        height: auto;
    }
    
    .homepage {
        padding: 20px;
        /* height: auto; */
       
        
    }
    
    .search-field {
        flex-direction: column;
        align-items: stretch; 
    }
    .search-field label {
        font-weight: bold;
        font-size: 20px;
    }

    
    
    
}
@media (min-width:768px) and (max-width:800px) {
    .Main-content{
        top: 150px;
    }
}
@media (max-width: 768px) {
    .homepage {
        padding: 20px;
       
    }
    
    .search-field {
        flex-direction: column;
        align-items: stretch;
    }

    .search-button {
        width: 80%; 
   
    }
}
.basic-multi-select .react-select__option {
    display: flex;
    align-items: center;
  }
  
  .basic-multi-select .react-select__option input {
    margin-right: 8px;
  }
  