.login-page {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background: url('../images/elegant-adult-couple.jpg') no-repeat center center;
    background: url("../images//background-img.jpg");
    background-size: cover;
    position: relative
    ;top:70px;
    margin-bottom: 80px;
}
.error-message{
    color:red;
}
.login-container {
    background: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    width: 423px;
}
.login-container form {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.login-container h2 {
    margin-bottom: 20px;
    font-size: 20px;

    span{
        color: #c1ab43;
    }
}
.login-container p a{
    color: #007BFF;
    text-decoration: none;
}

.login-container input {
    width: 96%;
    padding: 14px;
    margin: 14px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.forgot-password {
    display: block;
   font-weight: bold;
    color: #158ddd;
    text-decoration: none;
    align-self: flex-end;
    font-size: 12px;
    margin-bottom: 10px;
}
.forgot-password:hover {color: #5adaeb;}
.create-acc {
    font-size: 14px;

    a{
        font-size: 15px;
        font-weight: 500;
    }
 
}
.create-acc a{
    color: #007BFF;
    text-decoration: none;
    
}
.login-container form button{
    width: 60%;
    padding: 13px;
    color: white;
    border: none;
    border-radius: 26px;
    cursor: pointer;
    font-weight: 900;
    font-size: 16px;

}
.login-container form .or-button{
margin: 10px 0;
font-size: 10px;}
.login-button {
    background: linear-gradient(to right, #6cb2f4, #30ddcf);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    margin-top: 20px;
}
.login-button-OTP{ 
    color: #30ddcf !important;
    background: transparent;
    border: solid 1px rgb(49 196 190 / 75%) !important;
}


.sign-up-section {
    margin-top: 20px;
}

.sign-up-button {
    display: inline-block;
    padding: 10px 20px;
    background: #2c9fec;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    width: 80%;
}
.otp-input{
    width: 94%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    color: #998e8e;

    background: rgba(255, 255, 255, 0.9);
}
.otp-input input{
    border: none;
    padding: 0;
    width: 50%;
    margin: 0;
    outline: none;
    
}
.error{
    color: red;
}
