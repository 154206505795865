.navigation {
  padding:auto;
  z-index: 3;
  vertical-align: middle;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  color: black;
  background: rgb(255 255 255);
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); 
  transition: background-color 0.3s, box-shadow 0.3s;
  top: 0;
}

.landing-nav{
  justify-content: space-around;
}

.logo {
  width: 70px;
  margin: 10px;
  margin-left: 35px;
  height: 60px;
  margin-top: 15px;
}

.brand-name {
  text-decoration: none;
  color: #333; 
  display: flex;
  align-items: center;
  font-weight: bold;
}
.brand-name img{
  width: 120px;
}
.logo-head {
  font-size: 25px; 
  background: -webkit-linear-gradient(0deg,rgb(72, 124, 246), rgb(87 130 229) 0%, #30ddcf);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;

}

.navigation-menu {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  

}
.sign-buttons{
  display: flex;
}
.navigation-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0;
  margin-right: 20px;
  
  padding: 0;
  margin: 0;
  list-style: none;
}
.navigation-menu li {
  padding: 10px 15px; 
  transition: background-color 0.2s;
 
  margin: 0 0.5rem;
  font-weight: 600;
  font-size: 18px;
}
@media (max-width:1100px)  {
  .navigation-menu li {
    padding: 8px 6px; }
}


.navigation-menu li a {
  text-decoration: none;
  color: #555; /* Fallback color */
  width: 100%;
  
  /* Gradient background */
  background: -moz-linear-gradient(0deg, rgba(94, 50, 225, 1) 0%, rgb(49 196 190 / 75%) 100%);
  background: -webkit-linear-gradient(0deg, rgb(87 130 229) 0%, rgb(49 196 190 / 75%) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-weight: bold;
  position: relative;
}

.navigation-menu li a::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: -moz-linear-gradient(0deg, rgba(94, 50, 225, 1) 0%, rgb(49 196 190 / 75%) 100%);
  background: -webkit-linear-gradient(0deg, rgb(87 130 229) 0%, rgb(49 196 190 / 75%) 100%);
  opacity: 0; /* Initially invisible */
  transition: opacity 0.2s ease-out;
}

.navigation-menu li a:hover::before {
  animation: slideIn 0.5s forwards; /* Trigger the animation on hover */
}

/* Keyframe animation */
@keyframes slideIn {
  0% {
    width: 0;
    opacity: 1; /* Visible at the start */
  }
  50% {
    width: 100%; /* Expands to full width */
    opacity: 1;
  }
  75%{
    width: 100%; /* Expands to full width */
    opacity: 0.2;
  }
  100% {
    width: 100%;
    opacity: 0; /* Invisible at the end */
  }
}

.submenu-arrow{
  vertical-align: middle;
  font-size: 15px;
}
.hamburger {
  border: none;
  border: 0;
  margin: 0;
  height: 50px;
  width: 50px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #007bff;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  position: absolute;
  top: 50%;
  right: 48px;
  transform: translateY(-50%);
  display: none;
}
.buttonnav {
  background-color: orangered; 
  color: #ffffff; 
  padding: 10px 20px; 
  border-radius: 30px;
  border: 0px solid;
  height: 40px;
  font-weight: bold;
  margin: auto;
  margin-right: 20px;
  font-size: 15px;
}

.hamburger:hover {
  background-color:  rgba(41, 173, 169, 0.75);
}

.signin-way{
  list-style-type: none;
display: flex;
gap: 10px;
align-items: center;
}
.signin-way li .button-login{
  text-decoration: none;
  padding: 10px 30px;
  background-color: #2c9fec;
  border-radius: 32px;
  color: #fff;
  font-weight: bold;
  display: inline-block;
}

@media (max-width:925px)and (min-width:768px) {
  .brand-name img{
    width: 100px;
  }
  .navigation{
    flex-direction: column;
    height: 150px;
    gap: 5px;
  }
  .navigation-menu ul{
    justify-content: center;
  }
.logo-head{
  margin: 0;
}
.navigation-menu {
  width: 100%;}


}
@media screen and (max-width:925px) {
  .landing-nav{flex-direction: column;
  background-color: #fff !important;
height: fit-content;}
.sign-up-page{
position: relative !important;
right: 0 !important;
top: 100px;
}
.signin-way li p a{
  font-size: 10px;
}
.sign-up-container{
  padding: 13px !important;

  width: 319px !important;
}
.login-container{
  padding: 13px !important;

  width: 319px !important;
}
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }
  .navigation-menu{
    width: 60%;
    
  }

  .hamburger.onClick + .navigation {
    background-color: #DCE7F6;
  }
  .logo {
    width: 100px;
    margin: 10px;
    margin-left: 20px;
    height: 60px;
    
  }
  .logo-head{
    font-size: 27px;
  }
  .navigation{
    padding: 0;
    padding-top: 10px;
    width: 96%;
  }
  .navigation-menu ul {
    display: none;
  }
  .navigation-menu ul {
    position: absolute;
    top: 5px;
    z-index: -1;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #DCE7F6;
    
    
  }
  .navigation-menu li {
    text-align: left;
    margin-top: 30px;
    margin-left: 30px;
  }
  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
    
  }

  
  .navigation-menu.expanded ul {
    display: block;
  
    border-radius: 20px;
  }
  .buttonnav{
    display: none;
  }
}



@media (max-width:800px){
  .navigation-menu{
    width: 100%;
    
  }
  .navigation-menu li{
    font-size: 15px;
  }
  .hamburger.onClick + .navigation {
    background-color: #e9f0fa;
  }
  .logo {
    width: 100px;
    margin: 0px;
    margin-left: 0px;
    height: 60px;
    
  }
  .logo-head{
    font-size: 30px;
    padding-left: 10px;
    color: #007bff;
  }
  .navigation{
    padding: 0;
    padding-top: 0px;
    width: 100%;
 
  }
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.xmark{
  align-self: end;
  align-self: end;
  color: black !important;
  background: -webkit-linear-gradient(0deg, rgb(87 130 229) 0%, rgb(49 196 190 / 75%) 100%);
  background-clip: text;

  font-weight: bold;

}
.xmark:hover {
  animation: rotate 0.5s linear;
}

.container{
  width:20%;
  padding: 10px;
  height:300px;
  background: rgb(0, 0, 0, 0.8);
  background: white;
  position: absolute;
  top:65px;
  right: 0;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: start;
 
}
.container a{
  text-decoration: none;

  
}
.container a p{color: #DCE7F6;}
@media (max-width:925px)and (min-width:768px) {
  .container{
    top: 88px;
  }
}
.container button{
  border: none;
  color: #ffff;
  background-color: transparent;
  display: block;
  font-size: 20px;
  font-weight: 100;
  cursor: pointer;
    color: #555;
    width: 100%;
    background: -webkit-linear-gradient(0deg, rgb(87 130 229) 0%, rgb(49 196 190 / 75%) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    position: relative;
    text-align: left;
}
/* From Uiverse.io by vinodjangid07 */ 
.plans-btn {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 40px;
  background: linear-gradient(to right,#bf953f,#fcf6ba,#b38728,#fbf5b7,#aa771c);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 0.8em;
  color: rgb(121, 103, 3);
  font-weight: 600;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition-duration: 3s;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.144);
  background-size: 200% 200%;
}

.logoIcon path {
  fill: rgb(121, 103, 3);
}

.plans-btn:hover {
  transform: scale(0.95);
  transition-duration: 3s;
  animation: gradient 5s ease infinite;
  background-position: right;
}























@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }
  
  .navigation-menu ul {
    position: absolute;
 
    left: 0;
    width: 100%;
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    display: none; 
  }

  .navigation-menu.expanded ul {
    display: flex; 
  }

  .navigation-menu li {
    width: 100%;
    text-align: center;
  }

  .buttonnav {
    display: none; 
  }
  .container{
    width: 40%;
  }
}


.buttonnav {
  background-color: #007bff; 
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-weight: bold;
  transition: background-color 0.2s;
}

.buttonnav:hover {
  background-image: linear-gradient(to right, #4b8eec, #6cb2f4, #30ddcf);
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}
.pre-btn{
  background: #251c08;
  border: none;

  padding: 12px;
  color: white;
  border-radius: 10px;
}
.pre-btn span{
  font-size: 16px;
}
.pre-btn img{
  width:38px;
}
.pre-btn .pre-icon{
  color: gold;
  font-size: 20px;
    vertical-align: middle;
}
