.forgot-password-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f9;
    font-family: Arial, sans-serif;
  }
  
  .forgot-password-page h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .forgot-password-page form {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .forgot-password-page input {
    width: 94%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .forgot-password-page div {
    width: 94%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    color: #998e8e;
  }
  .forgot-password-page div input{
    border: none;
    padding: 0;
    width:50%;
    margin: 0;
    outline: none;
  }
 
  .forgot-password-page button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .forgot-password-page button:hover {
    background-color: #0056b3;
  }
  
  .forgot-password-page .success-message {
    color: #28a745;
  }
  
  .forgot-password-page p {
    color: #dc3545;
    text-align: center;
  }
  