.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #ffffff;
 
  }
  .not-found-container img{
    width:30%;
  }
  
  .not-found-title {
    font-size: 6rem;
    color: #ff6b6b;
    margin-bottom: 1rem;
  }
  
  .not-found-message {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .not-found-button {
    background-color: #4caf50;
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .not-found-button:hover {
    background-color: #388e3c;
  }
  